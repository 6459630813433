import { DataGraphConstants } from "../../../Constants/DataGraphConstants";
import { PriceChartConstants } from "../../../Constants/PriceChartConstants";
import { RIPanelOneilConstants } from "Constants/RIPanelOneilConstants.js";
import RiPanelOneilListSettings from "../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/RelatedInfoPanel/RIPanelOneilListSettings";
import { SettingsConstants } from "../../../Constants/SettingsConstants";
import SettingsStore from "SettingsStore";
import UserInfoUtil from 'UserInfoUtil';

const { ActionTypes } = RIPanelOneilConstants;
const initState = {
    researchAnalystList: [],
    researchList: {},
    focusList: [],
    historicalList: [],
    currentEntitlementInfo: [],
    currentPanelSettings: [],
    showHistorical: false,
    flagToShow: false,
    researchUrl: '',
    contactType: 'email',
    entitlementDialogFlag: false,
    entitlmentUserName: UserInfoUtil.getUserInfo() && UserInfoUtil.getUserInfo().name,
    entitlmentUserEmail: UserInfoUtil.getUserInfo() && UserInfoUtil.getUserInfo().userEmail,
    validationUserNameClass: 'displayNone',
    validationUserEmailClass: 'displayNone',
    entitlmentUserPhone: '',
    validationUserPhoneClass: 'displayNone',
    ideasData: {},
    ideaCurrencyCode: "",
    ideasDisplay: [],
    tempIdeas: [],
    stick: [],
    selectIdeaType: {},
    showBox: false,
    OneilListSettings: new RiPanelOneilListSettings(),
    currentEntitlementList: [],
};

const RIPanelOneilReducers = (state = initState, action) => {
    switch (action.type) {
        // case SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT:
        //     return {
        //         ...initState
        //     }
        case ActionTypes.GET_RESEARCH_ONEIL_INFO:
            return {
                ...state,
                researchList: action.researchList,
                researchUrl: action.researchUrl,
            }
        case ActionTypes.GET_RESEARCH_ANALYSTLIST_INFO:
            return {
                ...state,
                researchAnalystList: action.researchAnalystList,
                OneilListSettings: action.OneilListSettings
            }
        case ActionTypes.UPDATE_RI_LEADER_FLAG_STATUS_SAVE:
            return {
                ...state,
                currentEntitlementArray: action.currentEntitlementArray,
                flagToShow: !state.flagToShow
            }
        case ActionTypes.GET_FOCUS_LIST_INFO:
            return {
                ...state,
                focusList: action.focusList,
                historicalList: action.historicalList,
            }
        case ActionTypes.GET_HISTORICAL_LIST:
            return {
                ...state,
                historicalList: action.historicalList,
            }
        case ActionTypes.GET_CURRENT_ENTITLEMENT_INFO:
            return {
                ...state,
                currentEntitlementInfo: action.currentEntitlementInfo,
            }
        case ActionTypes.UPDATE_RI_PANEL_SETTINGS_DATA:
            return {
                ...state,
                currentPanelSettings: action.currentPanelSettings,
            }
        case ActionTypes.UPDATE_RI_HISTORIC_FLAG_STATUS:
            return {
                ...state,
                showHistorical: action.showHistorical,
            }
        case ActionTypes.SHOW_MAIN_FLAG:
            return {
                ...state,
                flagToShow: action.flagToShow
            }
        case ActionTypes.GET_IDEALIST_DATA:
            return {
                ...state,
                ideasData: action.ideasData
            }
        case SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT:
        case ActionTypes.ON_CLEAR_RI_PANEL_ONEIL_DATA:
            return {
                ...initState,
                researchList: state.researchList,
                OneilListSettings: state.OneilListSettings,
                showHistorical: state.showHistorical
            }
        case ActionTypes.UPDATE_CONTACT_TYPE:
            return {
                ...state,
                contactType: action.contactType,
            }
        case ActionTypes.UPDATE_ENTITLEMENT_DIALOG_FLAG:
            return {
                ...state,
                entitlementDialogFlag: action.entitlementDialogFlag,
            }
        case ActionTypes.UPDATE_ENTITLEMENT_USER_NAME:
            return {
                ...state,
                entitlmentUserName: action.entitlmentUserName,
                validationUserNameClass: action.validationUserNameClass
            }
        case ActionTypes.UPDATE_ENTITLEMENT_USER_EMAIL:
            return {
                ...state,
                entitlmentUserEmail: action.entitlmentUserEmail,
                validationUserEmailClass: action.validationUserEmailClass
            }
        case ActionTypes.UPDATE_ENTITLEMENT_USER_PHONE:
            return {
                ...state,
                entitlmentUserPhone: action.entitlmentUserPhone,
                validationUserPhoneClass: action.validationUserPhoneClass
            }
        case DataGraphConstants.ActionTypes.UPDATE_ONEIL_IDEAS_INFO:
            return {
                ...state,
                ideasData: action.ideasData,
                ideaCurrencyCode: action.currCode,
            }
        case PriceChartConstants.ActionTypes.ONEIL_IDEAS_INFO:
            return {
                ...state,
                ideasDisplay: action.ideasDisplay
            }
        case PriceChartConstants.ActionTypes.ONEIL_TEMP_IDEAS_INFO:
            return {
                ...state,
                tempIdeas: action.tempIdeas
            }
        case PriceChartConstants.ActionTypes.STORE_STICK_IDEAS:
            return {
                ...state,
                stick: action.stick
            }
        case PriceChartConstants.ActionTypes.SHOW_IDEA_BOX:
            return {
                ...state,
                showBox: action.showBox,
                ideasDisplay: action.ideasDisplay
            }
        case PriceChartConstants.ActionTypes.SELECTED_IDEA:
            return {
                ...state,
                selectIdeaType: action.selectIdeaType
            }
        case PriceChartConstants.ActionTypes.CLEAR_RI_PANEL_ONEIL_INFO:
            return {
                ...state,
                showHistorical: false,
                currentEntitlementInfo: [],
                researchAnalystList: [],
                focusList: [],
                historicalList: [],
            }
        default:
            return state;
    }
}
export default RIPanelOneilReducers;