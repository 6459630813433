module.exports = {
  "262626": "PCCCCCC262626",
  "330000": "PFDBCBC330000",
  "333300": "PFFFF9A333300",
  "340033": "PCDCCFF340033",
  "340066": "P9866FF340066",
  "660099": "P6734CC660099",
  "666777": "#333",
  "666888": "#888",
  "670000": "PFF0000670000",
  "676700": "PFFFF00676700",
  "990000": "PCC0100990000",
  "999000": "#000",
  "999900": "PCBCB01999900",
  "999999": "P4C4C4C999999",
  "sprite": "/Asset/images/lightSprite.png",
  "sprite2x": "/Asset/images/lightSprite2x.png",
  "imageSprite": "/Asset/images/imgSprite.png",
  "chartGridColor": "#999999",
  "eighteenMnth": "#999999",
  "fff333": "#333",
  "fff000": "#000",
  "000fff": "#fff",
  "fff666": "gray",
  "ddd333": "#333",
  "999ccc": "#ccc",
  "f1f1f1EBEBEB": "#EBEBEB",
  "E1E20FEFEF0": "#FEFEF0",
  "999999333333": "#333333",
  "1E1E1FDBDCDC": "#DBDCDC",
  "2C2C2CE9E9E9": "#E9E9E9",
  "000000F2F2F2": "#F2F2F2",
  "E0E189FF6700": "#FF6700",
  "CCCCCC262626": "#262626",
  "000000999999": "#999999",
  "999999000000": "#000000",
  "191919666666": "#666666",
  "3B3C42CCCCCC": "#CCCCCC",
  "77CAFE04478A": "#04478A",
  "scaleText": "#000000",
  "insiderLine": "#333333",
  "dateText": "#333333",
  "dividerColor": "#999999",
  "overBought": "#FF0000",
  "overSold": "#08cb00",
  "PTOIndex": "#999999",
  "PTOEStock": "#5B1B00",
  "PTOSStock": "#003300",
  "grayCmLb": "#333333",
  "sma10": "#00D3FF",
  "tiLabel": "#333333",
  "000000666666": "#000000",
  "applicationHtml": "#eeeef0",
  "applicationBody": "#eeeef0",
  "AppBackground": "#ffffff",
  "AppSecondaryBackground": "#efefef",
  "activeHighlightBackground": "#b8daff",
  "textDefault": "#333333",
  "textTitles": "#333333",
  "inactiveText": "#666666",
  "linkText": "#003399",
  "linkTextMouseover": "#000000",
  "positiveDataText": "#018fee",
  "positiveDataTextHover": "#77cafe",
  "negativeDataText": "#ff33cc",
  "negativeDataTextHover": "#fd7ef2",
  "estimateDataText": "#ff6600",
  "positiveNode": "#2032f0",
  "negativeNode": "#de31ae",
  "textNewsStemp": "#333333",
  "grayText": "#888888",
  "dogear": "#E7B50B",
  "headerTabNavBg": "#eeeef0",
  "tabLabel": "#333",
  "tabBg": "#efefef",
  "tabBgHover": "#a2a2a6",
  "tabBgActive": "#fff",
  "tabDivider": "#999999",
  "tabDividerHover": "#999999",
  "tabLabelHover": "#ffffff",
  "tabLabelActive": "#000",
  "tabSearchInputBg": "#FFF",
  "tabSearchInputTxt": "#000",
  "volRateLabel": "#666666",
  "buttonLabel": "#ffffff",
  "buttonLabelShadow": "#000000",
  "buttonBgTop": "#848484",
  "buttonBgTopHover": "#a2a6ac",
  "buttonBgBottom": "#72737b",
  "buttonBgBottomHover": "#95969b",
  "buttonStroke": "#4a4a52",
  "buttonLabelGray": "#999999",
  "buttonLabelHover": "#ffffff",
  "buttonLabelShadowHover": "#000000",
  "ideasBgTop": "#E1E1E1",
  "ideasBgTopHover": "#D1D1D1",
  "ideasBgBottom": "#C8C8C8",
  "ideasBgBottomHover": "#B8B8B8",
  "buttonLabelActive": "#000000",
  "buttonLabelShadowActive": "#b8daff",
  "buttonBgActive": "#b8daff",
  "buttonLabelInactive": "#999999",
  "buttonLabelShadowInactive": "#000000",
  "buttonBgTopInactive": "#848484",
  "buttonBgBottomInactive": "#72737b",
  "buttonSecondaryLabel": "#333333",
  "buttonSecondaryBgTop": "#f6f6f6",
  "buttonSecondaryBgBottom": "#dbdbdb",
  "buttonSecondaryBgBottomHover": "#f6f6f6",
  "buttonSecondaryStroke": "#666666",
  "buttonStrokeInactive": "#4a4a52",
  "buttonInfoBgTop": "#385f77",
  "buttonInfoBgTopHover": "#3797e0",
  "buttonInfoBgBottom": "#183343",
  "buttonInfoBgBottomHover": "#1b5e9b",
  "financialDividerLines": "#999999",
  "contextMenuText": "#000000",
  "contextMenuBg": "#ececec",
  "contextMenuStroke": "#b3b3b3",
  "contextMenuListHover": "#bddeff",
  "contextMenuInactiveText": "#666666",
  "contextMenuDividers": "#b3b3b3",
  "contextMenuCheckmark": "#000000",
  "contextMenuSecondaryLinks": "#006699",
  "contextMenuListHoverTop": "#c8e3fe",
  "contextMenuListHoverBottom": "#a9d2fe",
  "fundamentalConsoleBlockTitleTop": "#dedede",
  "fundamentalConsoleBlockTitleBottom": "#ffffff",
  "fundamentalConsoleRowtableBgHover": "#cccccc",
  "fundamentalConsoleSearchResultBg": "#ebebeb",
  "fundamentalConsoleValue": "#095c91",
  "fundamentalConsoleTitle": "#588f1a",
  "tableHeaderBgTop": "#fefefe",
  "tableHeaderBgBottom": "#d5d5d5",
  "fundamentalConsoleExploreListBg": "#b8daff",
  "fundamentalConsoleExploreListColor": "#000000",
  "headCellTableHeaderTop": "#fefefe",
  "headCellTableHeaderBottom": "#d5d5d5",
  "fundamentalConsoleSortingTop": "#828387",
  "fundamentalConsoleSortingBottom": "#707276",
  "fundamentalConsoleSortingTopHover": "#606267",
  "fundamentalConsoleSortingBottomHover": "#44474d",
  "fundamentalRadioBorder": "#ccc",
  "headTableGreenHeading": "#558406",
  "headTableGrayTxt": "#000",
  "fundamentalFrozenBorder": "#fff",
  "fundamentalValueLessDay": "#fd8548",
  "fundamentalValueLessDayHover": "#000",
  "zebraStripeColor": "#ECEAEA",
  "footerBg": "#ebebeb",
  "footerTabBgTop": "#b1b4b8",
  "footerTabBgBottom": "#878991",
  "footerTablinkText": "#003399",
  "footerTabActive": "#cdcfd6",
  "footerTabDisabled": "#555",
  "footerTabBorder": "#C9CDD2",
  "footerlogoWrapBorder": "#d5d7de",
  "footerTablinkTextShadow": "#cccccc",
  "logoWrapBorderOne": "#dedede",
  "logoWrapBorderTwo": "#888888",
  "versionAppText": "#000",
  "gridRowActiveBg": "#B8DAFF",
  "pointerColor": "#3b3c42",
  "riPanelBg": "#F7F7F7",
  "riBorderColor": "#666666",
  "riActionBg": "#D1D3D6",
  "startGradientColor": "#E3E4E4",
  "endGradientColor": "#BDBEBE",
  "ownershipBorderColor": "#999999",
  "ownershipHeaderBg": "#C9CCD1",
  "cellActiveBg": "#999999",
  "idealistWellBg": "#F3F3F3",
  "idealistWellText": "#666666",
  "holdingAmountBg": "#B9B9B9",
  "indicatorBrush1": "#46D9B2",
  "indicatorBrush2": "#FF8A48",
  "indicatorBrush3": "#888888",
  "activeFundBg": "#BBDCFB",
  "sideBarStartColor": "#E2E2E2",
  "sideBarEndColor": "#A2A2A2",
  "sideBarHoverStartColor": "#989898",
  "sideBarHoverEndColor": "#777777",
  "newsItemBorderColor": "#26A0DA",
  "allocationFontColor": "#333333",
  "ideaListFlagButtonBg": "#F3F3F3",
  "ideaListFlagActiveButtonBg": "#FFFFFF",
  "entitlementBtnStartBg": "#e1e1e1",
  "entitlementBtnEndBg": "#c8c8c8",
  "entitlementBorderBrush": "#5c5c5c",
  "sectorWeightingGraphColor": "#cccccc",
  "sectorWeightingGraphBarColor": "#ACD166",
  "newsReloadColor": "#90EE90",
  "idealistflagBorderColor": "#CCCCCC",
  "riPanelBorderColor": "#666666",
  "riPanelHeaderStartBg": "#e3e4e4",
  "riPanelHeaderEndBg": "#bdbebe",
  "periodicityBGBrushStart": "#838488",
  "periodicityBGBrushStop": "#707276",
  "hubNavigationLabel": "#C9CCD1",
  "hubFocusText": "#000000",
  "hubNormalText": "#838488",
  "hubText": "#838488",
  "hubLiPoint": "black",
  "A9A9A9262626": "#262626",
  "miniListPanelBg": "#F7F7F7",
  "miniListName": "#003399",
  "miniListItemColor": "#000000",
  "miniListNameHover": "#000000",
  "sideBarDotsColor": "#6d6666",
  "miniListSelectedBg": "#DDDDDD",
  "miniListSelectedBdr": "#666",
  "miniListSelectedHover": "#000",
  "miniListDividerBorderColor": "#999999",
  "miniListRepeatArrowColorA": "#485461",
  "miniListRepeatArrowColorB": "#485461",
  "chartSeparatorBorder": "#999",
  "financialTitleBar": "#646973",
  "financialHorizontalGridBg": "#cccccc",
  "financeBlockTitleBarBgTop": "#c9cacb",
  "financeBlockTitleBarBgBottom": "#afb0b1",
  "financeBlockTitleColor": "#04478A",
  "activePage": "#777",
  "inActivePage": "#b2b2b2",
  "summaryBlockBg": "#ffffff",
  "summaryBlockTitleBg": "#F7F7F7",
  "summaryBlockSwatchColor": "#000",
  "summaryBlockInfoRowBg": "#fff",
  "summaryBlockBorderColor": "#999999",
  "summaryBlockHeaderText": "#000",
  "summaryBlockHeaderCell": "#000",
  "summaryBlockHeaderCellBg": "#dedede",
  "summaryBlockWeeklyHover": "#CCC",
  "summaryBlockWeeklyText": "#000",
  "summaryBlockWeeklyBg": "#F7F7EF",
  "SummaryBlockCarouselIndicatorsBg": "#808080",
  "summaryBlockCRIcon": "-200px -200px",
  "summaryBlockDogEarBg": "yellow",
  "summaryBlockDogEarColor": "#000",
  "summaryBlockDeBorderBg": "#FFFF99",
  "summaryBlockBackgroundPosition": "-50px 0",
  "summaryBlockBackgroundHoverPosition": "-50px -33px",
  "summaryBlockLoadingBackGround": "/Asset/images/SummaryBlock_Bg_Lt.png",
  "summeryBlockIndustryGroupText": "#333",
  "verticalScrollMiddle": "#E7E7EA",
  "verticalScrollMiddleBorder": "#666666",
  "verticalScrollBackground": "#C5C5CE",
  "verticalScrollBorder": "#999999",
  "horizontalScrollMiddle": "#E7E7EA",
  "horizontalScrollMiddleBorder": "#666666",
  "horizontalScrollBackground": "#C5C5CE",
  "horizontalScrollBorder": "#999999",
  "ScrollVerticalUp": "-450px -150px",
  "ScrollVerticalDown": "-475px -150px",
  "ScrollHorizontalLeft": "-450px -175px",
  "ScrollHorizontalRight": "-475px -175px",
  "loadingSpinner": "/Asset/images/loading-light.gif",
  "layoutLoadingSpinner": "/Asset/images/loading-dark.gif",
  "pePsBgTop": "#f9f9f9",
  "pePsBgBottom": "#d6d6d6",
  "pePsBorder": "#30373F",
  "pePsColor": "#000",
  "blockTitleBarBg": "#D6D6D6",
  "tabViewBgTop": "#efefef",
  "tabViewBgBottom": "#cccccc",
  "tabViewBgBorder": "#7f7f7f",
  "tabViewBottomFooterTop": "#aaabb0",
  "tabViewBottomFooterBottom": "#828387",
  "groupFilterColor": "#a9a9a9",
  "groupFilterBadgeElementColor": "#000000",
  "groupFilterBg": "#E1E1E1",
  "gridHeaderTop": "#f2f2f2",
  "gridHeaderBottom": "#c5c5c5",
  "gridHeadeHoverTop": "#aaabb0",
  "gridHeaderHoverBottom": "#828387",
  "gridHeadeActiveTop": "#CBCBCD",
  "gridHeaderActiveBottom": "#A9AAAB",
  "gridHeaderActiveBorder": "#5c5c5c",
  "sortingOrder": "#096004",
  "gridloading": "#FFFFFF",
  "gridRowOddBg": "#eee",
  "gridRowEvenBg": "#fff",
  "gridRowOddEvenColor": "#444",
  "gridCellSymbolText": "#337ab7",
  "treeViewItemHover": "#E2E2E2",
  "oNeilTabActive": "#DDD",
  "groupListInnerSection": "#C7CACD",
  "containerSwitchTab": "#AFB3B6",
  "groupListInnerBlock": "#ffffff",
  "groupBoxBorder": "#8B929B",
  "groupBoxHover": "#ECF5FF",
  "bottomTabBrowserFilterBg": "#C7CACD",
  "bottomTabBrowserFilterBgInactive": "#AFB3B6",
  "bottomTabBrowserFilterBgHover": "#C7CACD",
  "bottomTabBrowserFilterInactiveColor": "#444",
  "gridRowSelected": "#dedede",
  "gridRowSelectedBorder": "#222222",
  "listPropertiesBg": "#ebebeb",
  "columnSetTitle": "#333333",
  "listBg": "#eeeef0",
  "listActiveText": "#333",
  "filterBadgeElement": "#095c91",
  "listPropertiesDetails": "#000000",
  "oNeilTabActiveBorder": "#444",
  "oNeilTabActiveColor": "#000",
  "listGridloader": "#FFF",
  "symbolEntryBorderColor": "#cccccc",
  "symbolEntryMenuBorderColor": "#cccccc",
  "footerRotationBG": "#F4F4F4",
  "footerBottomYear": "#D6D7D9",
  "rotationCycleArrow1": "#d3d3d3",
  "rotationCycleArrow2": "#e0e0e0",
  "rotationAxisLine": "#cccccc",
  "headCenterRotation": "#000",
  "headHighLevelRotation": "#333",
  "headLowLevelRotation": "#999",
  "RotationsSymbolName": "#000",
  "RotationsRiPanelBde": "#ccc",
  "RotationsRiPanelHeadTopBdr": "#ccc",
  "RotationsRiPanelHeadBottompBdr": "#ccc",
  "graphChartAreaPathBg": "#D6D7D9",
  "graphChartAreaPathBorder": "#7b7e81",
  "graphListActionButtonBg": "#eeeef0",
  "graphListActionButtonBorder": "#bbb",
  "graphListActionButtonBgCustomtop": "#fff",
  "graphListActionButtonBgCustomButtom": "#ddd",
  "graphListActionButtonBgCustomtopHover": "#fff",
  "graphListActionButtonBgCustomButtomHover": "#ccc",
  "compareRiPanelBorder": "#5C5C5C",
  "graphtabBg": "#E5EDF1",
  "RotationsHeadTopBdr": "#8d8d8d",
  "rangeThumbHandle": "-350px -450px",
  "rangeThumbHandleHover": "-425px -450px",
  "rangeThumbHandleActive": "-500px -450px",
  "uptrendStroke": "#2736E9",
  "downtrendStroke": "#DE32AE",
  "defaultScaleText": "#000000",
  "labelRotation": "#333",
  "labelRotationGrey": "#999",
  "00339904478a": "#04478a",
  "a5a5a5333333": "#333",
  "77cafe04478a": "#04478a",
  "ffffff333333": "#333333",
  "333333d3d3d3": "#d3d3d3",
  "77cafe003399": "#003399",
  "0f0f109c9d9f": "#9c9d9f",
  "defaultKPIcolor": "#999999",
  "P000000FFFFFF": "#FFFFFF",
  "P262626CCCCCC": "#CCCCCC",
  "P4C4C4C999999": "#999999",
  "P9999994C4C4C": "#4C4C4C",
  "PCCCCCC262626": "#262626",
  "PFFFFFF000000": "#000000",
  "P00333399FFFF": "#99FFFF",
  "P00666633FFFF": "#33FFFF",
  "P00999900CCCC": "#00CCCC",
  "P00CCCC009999": "#009999",
  "P33FFFF006666": "#006666",
  "P99FFFF003333": "#003333",
  "P00003399CCFF": "#99CCFF",
  "P013CB366CCFF": "#66CCFF",
  "P0066CC3399FF": "#3399FF",
  "P3399FF0066CC": "#0066CC",
  "P66CCFF013CB3": "#013CB3",
  "P99CCFF000033": "#000033",
  "P013CB3013CB3": "#013CB3",
  "P000000111111": "#111111",
  "P340033CDCCFF": "#CDCCFF",
  "P3400669866FF": "#9866FF",
  "P6600996734CC": "#6734CC",
  "P6734CC660099": "#660099",
  "P9866FF340066": "#340066",
  "PCDCCFF340033": "#340033",
  "P330000FDBCBC": "#FDBCBC",
  "P670000FF0000": "#FF0000",
  "P990000CC0100": "#CC0100",
  "PCC0100990000": "#990000",
  "PFF0000670000": "#670000",
  "PFDBCBC330000": "#330000",
  "P5B1B00FFCC99": "#FFCC99",
  "PAE3E00FDA57D": "#FDA57D",
  "PFC6901FD8548": "#FD8548",
  "PFD8548FC6901": "#FC6901",
  "PFDA57DAE3E00": "#AE3E00",
  "PFFCC995B1B00": "#5B1B00",
  "P333300FFFF9A": "#FFFF9A",
  "P676700FFFF00": "#FFFF00",
  "P999900CBCB01": "#CBCB01",
  "PCBCB01999900": "#999900",
  "PFFFF00676700": "#676700",
  "PFFFF9A333300": "#333300",
  "P00330099FF99": "#99FF99",
  "P00660034FF33": "#34FF33",
  "P08980009CB01": "#09CB01",
  "P09CB01089800": "#089800",
  "P34FF33006600": "#006600",
  "P99FF99003300": "#003300",
  "FFFFFF": "P000000FFFFFF",
  "CCCCCC": "P262626CCCCCC",
  "4C4C4C": "P9999994C4C4C",
  "000000": "PFFFFFF000000",
  "99FFFF": "P00333399FFFF",
  "33FFFF": "P00666633FFFF",
  "00CCCC": "P00999900CCCC",
  "009999": "P00CCCC009999",
  "006666": "P33FFFF006666",
  "003333": "P99FFFF003333",
  "99CCFF": "P00003399CCFF",
  "66CCFF": "P013CB366CCFF",
  "3399FF": "P0066CC3399FF",
  "0066CC": "P3399FF0066CC",
  "013CB3": "P66CCFF013CB3",
  "000033": "P99CCFF000033",
  "CDCCFF": "P340033CDCCFF",
  "9866FF": "P3400669866FF",
  "6734CC": "P6600996734CC",
  "FDBCBC": "P330000FDBCBC",
  "FF0000": "P670000FF0000",
  "CC0100": "P990000CC0100",
  "FFCC99": "P5B1B00FFCC99",
  "FDA57D": "PAE3E00FDA57D",
  "FD8548": "PFC6901FD8548",
  "FC6901": "PFD8548FC6901",
  "AE3E00": "PFDA57DAE3E00",
  "5B1B00": "PFFCC995B1B00",
  "FFFF9A": "P333300FFFF9A",
  "FFFF00": "P676700FFFF00",
  "CBCB01": "P999900CBCB01",
  "99FF99": "P00330099FF99",
  "34FF33": "P00660034FF33",
  "09CB01": "P08980009CB01",
  "089800": "P09CB01089800",
  "006600": "P34FF33006600",
  "003300": "P99FF99003300",
  "ownershipTabStuffBg": "#FFF",
  "ownershipDigitboxTxt": "#000",
  "ownershipDigitboxBorder": "#999999",
  "ownershipTblBg": "#F3F3F3",
  "ownershipInsideDomTab": "#808080",
  "ownBlackGridbgOdd": "#EEE",
  "ownBlackGridbgEven": "#FFF",
  "ownGrayGridbgOdd": "#F0F0F0",
  "ownGrayGridbgEven": "#E1E1E1",
  "ownershipHeadPass": "#333",
  "ownershipContentHolder": "#F3F3F3",
  "ownershipContentGridBg": "#F3F3F3",
  "ownershipGridScrollSeprationLine": "#999",
  "ownershipHeaderBottomBorderColor": "#313840",
  "ownershipScrollPath": "#F3F3F3",
  "ownershipScrollPathHover": "#C2C5C9",
  "ownershipGridAlterBlackBorderEvenLight": "#E7E7E7",
  "ownershipGridAlterGrayBorderEvenLight": "#E4E4E4",
  "ownershipGridAlterBlackBorderEvenDark": "#F7F7F7",
  "ownershipGridAlterGrayBorderEvenDark": "#F4F4F4",
  "ownershipCellHoverColor": "#04478A",
  "ownershipGridHeadHoverLeft": "#A8A9AE",
  "ownershipGridHeadHoverRight": "#898A8E",
  "ownershipGridHeadSelectiveLeft": "#D5D6D7",
  "ownershipGridHeadSelectiveRight": "#C5C6C7",
  "ownershipGridHeadHoverSelctiveTextColor": "#333",
  "ownershipOrderCounter": "#02AD02",
  "ownershipSelectedHighlight": "#BDDE7A",
  "ownershipClosingBtn": "#D3D3D3",
  "ownershipTabStuffTabText": "#FFF",
  "ownershipTabStuffTabBorder": "#717377",
  "ownershipTabStuffTabTopColor": "#818387",
  "ownershipTabStuffTabBotColor": "#717377",
  "ownershipTabStuffTabshadow": "#65676b",
  "ownershipTabStuffTabTopColorHover": "#ACACAC",
  "ownershipTabStuffTabBotColorHover": "#868686",
  "ownershipTabStuffTabshadowHover": "#616161",
  "ownershipTabStuffTabBorderHover": "#868686",
  "ownershipTabStuffTabColorActive": "#B8DAFF",
  "ownershipTabStuffTabBorderActive": "#AAD1FA",
  "ownershipTabClosingIcon": "-472px -97px",
  "ownershipTabClosingIconHover": "-472px -122px",
  "RIPanelOwnershipEntry": "#999",
  "backBtnBg": "-550px -50px",
  "backBtnBgHover": "-550px -75px",
  "checkListboxBorder": "#999999",
  "checklistItemHover": "#D2D2D2",
  "checklistInfoBg": "#EBEBEB",
  "checkListIsThreshold": "#D1D3D6",
  "ListgraphAreaMain": "#E8E8E8",
  "ListgraphAreaSecond": "#E8E8E8",
  "ListgraphAreaBorder": "#414143",
  "listResizeBarGraphBorderBottom": "#C8C8C8",
  "listResizeBarGraphBorderTop": "#999999",
  "listResizeBarGraphBorderBg": "#C8C8C8",
  "listResizeBarGraphBorderLinerGradientMain": "#C8C8C8",
  "listResizeBarGraphBorderLinerGradientSecond": "#C8C8C8",
  "barGraphinnerbg": "#ffffff",
  "barGraphinnerStrock": "#666666",
  "barToolTipBackground": "#eeefef",
  "barToolTipForground": "#000000",
  "listSummaryStatsDataOdd": "#e6e6e6",
  "listSummaryStatsDataEven": "#d8d8d8",
  "financialHover": "#ccc",
  "financialSpellHover": "#204687",
  "financialIconBg": "#c8daef",
  "financialWithActive": "#ccc",
  "financialSpellWithActiveHover": "#204687",
  "financialIconBgWithActive": "#ccc",
  "financialIconBgWithActiveHover": "#c8daef",
  "financialClicked": "#999",
  "financialSpellClickedHover": "#204687",
  "financialIconBgClickedHover": "#c8daef",
  "paginateViewGraph": "#FFF",
  "fundamentalIconBgForHover": "#ccc",
  "imageOfChanger": "-225px -100px",
  "BgOfChanger": "#204687",
  "HoverBgOfChanger": "#000",
  "imageOfFinancial": "-245px -99px",
  "hoverImageOfFinancial": "-245px -124px",
  "imageFinancialClicked": "-245px -99px",
  "normalHoverImageOfFinancial": "-245px -99px",
  "fefbce": "#fefbce",
  "8f8e7a": "#8f8e7a",
  "000": "#000",
  "summeryStratcer": "#8e8e8e",
  "summeryStateHoldBG": "#616363",
  "summeryStateHoldColor": "#000",
  "summeryStateHoldBGHover": "#b5b5b5",
  "summeryStateHoldColorHover": "#000",
  "summaryBlockBgPos": "-200px 0",
  "summaryBlockBgPosHover": "-200px -25px",
  "summaryBlockBgPosActiveHover": "-200px -25px",
  "listSummaryBlockTitle": "#B5B5B5",
  "listSummaryBlockTitleHover": "#D6D6DF",
  "listSummaryBlockTitleHoverColor": "#000",
  "electricUtilitiesTopBorder": "#4F5054",
  "crossHairWindowBG": "#f5f5f5",
  "crossHairWindowBorderColor": "#ccc",
  "crossHairLabel": "#555",
  "crossHairLabelValue": "#000",
  "newsBlockBg": "#DEDEDE",
  "newsDetailsTabData": "#000",
  "NewslistGroupItemEven": "#dedede",
  "NewslistGroupItemOdd": "#efefef",
  "newsBlockTitleBarBgTop": "#c9cacb",
  "newsBlockTitleBarBgBottom": "#afb0b1",
  "newsMaintabMainBlock": "#efefef",
  "newsFillAreaContent": "#ccc",
  "metrickDialogBg": "#CCCCCC",
  "metrickDialogHeaderTitleColor": "#000",
  "metrickDialogHeaderTopBorderColor": "#b1b4b8",
  "metrickDialogHeaderTopColor": "#b1b4b8",
  "metrickDialogHeaderbottomColor": "#878991",
  "metrickDialogHeaderbottomBorderColor": "#878991",
  "metrickDialogBodyTopBorderColor": "#6E6E6E",
  "metrickInnerBlockBg": "#F7F7EF",
  "metrickBtnDefaultTextColor": "#fff",
  "metrickBtnDefaultTextShadowColor": "#000",
  "metrickBtnDefaultborder": "#4D4F54",
  "metrickBtnDefaultBgTopColor": "#818387",
  "metrickBtnDefaultBgBottomColor": "#717377",
  "metrickBtnDefaultBgTopColorHover": "#acacac",
  "metrickBtnDefaultBgBottomColorHover": "#868686",
  "metrickLabelTextColor": "#000",
  "comDialogContentBorderColor": "#6E6E6E",
  "comDialogContentBG": "#CCC",
  "comDialogContentTextColor": "#333",
  "MsgDefMainWrap": "#FFF",
  "MsgTabNavBg": "#6C6F74",
  "MsgTabNavTextColor": "#fff",
  "MsgTabNavBgHover": "#FFF",
  "MsgTabNavTextColorHover": "#000",
  "MsgTabNavBgColorActive": "#FFF",
  "MsgTabNavBoxBG": "#CCC",
  "MsgTabNavBoxBottomBorderColor": "#FFF",
  "MsgTabNavContentBg": "#FFF",
  "MsgTabNavContentThBg": "#D8D8D8",
  "MsgTabNavContentThTextColor": "#000",
  "MsgTabNavContentTdBg": "#FFF",
  "MsgTabNavContentTdTextColor": "#747474",
  "MsgTabNavContentTdTextColorUnreadDetail": "#000",
  "MsgTabNavContentTdTextColorUnreadName": "#04478A",
  "MsgTabNavContentTdTextColorUnreadNameHover": "#000",
  "MsgTabNavContentTdTextColorReadDetail": "#737373",
  "MsgTabNavContentTdTextColorReadName": "#737373",
  "MsgTabNavContentTdTextColorReadNameHover": "#000",
  "MsgTabNavContentTdTextColorReadTrHover": "#000",
  "MsgTabNavContentTdTextColorTrHover": "#000",
  "MsgBlueTextColor": "#04478A",
  "MsgBlueTextColorHover": "#000",
  "notifyInnerChildBg": "#E8E8E8",
  "notifyInnerChildTextColor": "#000",
  "notifyInnerChildHLStockNameTextColor": "#158ce0",
  "notifyInnerChildHLStockNameTextColorHover": "#000",
  "notifyInnerChildHLStockDetailsTextColor": "#000",
  "notifyInnerChildCloseColor": "#158ce0",
  "notifyInnerChildCloseColorHover": "#000",
  "MsgDefTTBorderColor": "#666",
  "MsgDefTTBg": "#FEFBCE",
  "MsgDefTTTxtColor": "#000",
  "alePopLGIBg": "#fff",
  "alePopLGIBorderColor": "#CCCCCC",
  "alePopLGIActiveBg": "#B8DAFE",
  "alePopLGIPanelAColor": "#000",
  "PrefBtnColor": "#00528d",
  "ownerShipBlockMainPanelTopBorder": "#999",
  "ownershipBlockHeaderBG": "#FFF",
  "ownershipBlockRightHeaderLeftBorder": "#959494",
  "ownershipGridDataHoldBg": "#FFF",
  "ownershipGridDataBG": "#FFF",
  "ownershipGridDataRowHoverBG": "#CCC",
  "ownershipGridDataRowHoverBorder": "#656A70",
  "ownershipGridRowBorder": "#959494",
  "ownershipGridRowBG": "#FFF",
  "ownershipGridRowColor": "#000",
  "ownerTogglerBoxBG": "#ECEAEA",
  "ownerTogglerBoxBorder": "#959494",
  "ownerTogglerCellFont": "#000",
  "ownershipGridPercentileFont": "#000",
  "ownershipBlockleftHeading": "#04479F",
  "ownershipBlockrightYTDHeading": "#808080",
  "yellowDogear": "#E7B50B",
  "yellowDogearHover": "#FF0",
  "yellowDogearText": "#000",
  "yellowDogearHoldBG": "#FFFF99",
  "yellowDogearHoldBorder": "#000",
  "ownershipValueChanger": "#000",
  "loftBg": "#b1b2b4",
  "loftBgHover": "#d4d4d8",
  "ownershipBrecketsDottedBorder": "#000",
  "blackTooltipBg": "#FFF",
  "blackTooltipColor": "#000",
  "blackTooltipBorder": "#333",
  "blackTooltipShadow": "#BBB",
  "dataGraphTooltip": "#FEFBCE",
  "ownershipSubmenulistAction": "#369",
  "ownershipSubmenulistActionHover": "#000",
  "subOwnershiopText": "#000",
  "ownershipActionLInkSepretor": "#333",
  "ownershipGridDataRowDefaultColor": "#808080",
  "oneilRatingBtnBorder": "#CCC",
  "oneilRatingBtnBG": "#BABEC2",
  "oneilRatingBtnBorderIside": "#999",
  "oneilRatingBtnColor": "#000",
  "oneilRatingBtnHoverBG": "#BABEC2",
  "oneilRatingBtnHoverColor": "#000",
  "oneilRatingBtnActiveBG": "#ECECEC",
  "oneilRatingBtnActiveColor": "#000",
  "oneilRatingBtnBorderActiveHoverIside": "#999",
  "oneilRatingTabContentBorder": "#CCC",
  "oneilRatingTabContentBG": "#ECECEC",
  "oneilRatingTabContentPaneBorder": "#8B929B",
  "oneilRatingRestoreLinkColor": "#039",
  "oneilRatingRestoreLinkHoverColor": "#000",
  "oneilRatingApearanceBGColor": "#EBEBEB",
  "oneilRatingApearanceBorderColor": "#8B929A",
  "oneilRatingMACDMeasureCriteria": "#555",
  "oneilAboutRowBottomBorder": "#AFB3B6",
  "oneilAboutRowtopBorder": "#FFF",
  "oneilRatingLabelText": "#000",
  "oneilRatingMacdInputText": "#000",
  "accdisTiIndicatorholdBg": "#fff",
  "accdisTiIndicatorholdBorder": "#D5D6D8",
  "accdisTiIndicatorListColor": "#000",
  "liningParent": "#000",
  "liningParentBg": "#B4B4B4",
  "liningParentGradBgtop": "#f6f6f6",
  "liningParentGradBgBtm": "#B4B4B4",
  "liningParentGradBgtopHover": "#FFF",
  "liningParentGradBgBtmHover": "#D5D5D5",
  "liningHrBg": "#000",
  "liningHrColor": "#000",
  "appearanceBoxShadow": "#888",
  "appearanceBoxGradBgTop": "#f6f6f6",
  "appearanceBoxGradBgBtm": "#E0E0E0",
  "oneilAppearWeightBg": "#B8DAFF",
  "tiMacdErrorBox": "#666",
  "tiMacdErrorBG": "#FEFBCE",
  "tiMacdErrorColor": "#BB0812",
  "buttonActiveState": "#04CCFE",
  "dg-symbolStackBg": "#FFF",
  "dg-rayChartContainer": "#999",
  "dg-symbolChartTitle": "#032b44",
  "dg-symbolChartTitleHover": "#000",
  "dg-symbolChartTitleGray": "#333",
  "dg-typeNScale": "#333",
  "dg-typeNScaleHover": "#000",
  "dg-techIndicators": "#333",
  "dg-legendListText": "#333",
  "dg-techratingBg": "#D9D9DA",
  "dg-techratingText": "#666",
  "dg-logNLin": "#333",
  "dg-logNLinHover": "#000",
  "dg-copyRightsTxt": "#666",
  "dg-copyRightsTxtHover": "#000",
  "dg-copyRightsBg": "rgba(255, 255, 255, 0.7)",
  "dg-copyRightsTxtyellow": "#111",
  "dg-copyRightsBgyellow": "rgba(0, 0, 2, 0.7)",
  "dg-pricesDate": "#333",
  "dg-ohPercentHead": "#333",
  "dg-ohPercentHeadHover": "#000",
  "dg-ohPercentData": "#000",
  "dg-pivotPercentText": "#ff33cc",
  "dg-chartPanelLeftBg": "#FFF",
  "dg-chartMultiOptionMenuBtnTxt": "#FFF",
  "dg-chartMultipleTitle": "#000",
  "dg-chartMultipleTitleBorder": "#AEB0B3",
  "dg-chartCorporateEventBtn": "#FFF",
  "dg-intraDayBg": "#fff",
  "dg-intraDayBorder": "#2F3133",
  "dg-intraDayTitleColor": "#333",
  "dg-intraDayVolume": "#333",
  "dg-timeLineBorder": "transparent",
  "dg-timelineLeftSpacer": "#FFF",
  "dg-legendListPopover": "#FFF",
  "dg-legendListPopoverDiff": "#000",
  "dg-emailPopupPlaceHolder": "#999",
  "dg-toolTipBg": "#FFFFB3",
  "dg-toolTipTexts": "#555",
  "dg-toolTipTextsStrock": "#666",
  "dg-financialValueDigit": "#000",
  "dg-financialMetricMesure": "#333",
  "dg-financialMetricMesureValue": "#000",
  "dg-flyClustMenuBg": "#369",
  "dg-flyClustBtnStroke": "#4B82B7",
  "dg-flyClustBtnIconColor": "#FFF",
  "dg-flyClustBtnHoverBg": "#BDDEFF",
  "dg-flyClustBtnHoverColor": "#000",
  "dg-flyClustTitleColor": "#FFF",
  "dg-tiIndicatorSubMenuStrock": "#767B81",
  "dg-tiIndicatorDropDownBg": "#ECEFF3",
  "dg-tiIndicatorDropDownText": "#333",
  "dg-tiIndicatorDropDownArrow": "#333",
  "dg-selectFlyoutBorder": "#646973",
  "dg-tiIndicatorHoverBg": "#bddeff",
  "dg-tiIndicatordeactiveColor": "#777",
  "dg-flyClustToolTipText": "#000",
  "dg-flyClustSelectGroup": "#369",
  "dg-flyclustDragGroup": "#BDDEFF",
  "dg-sortable-drag": "#646973",
  "dg-flyClustStripBgOnHover": "#BDDEFF",
  "dg-flyClustStripBgOnDrag": "#BDDEFF",
  "dg-flyDragIconColor": "#FFF",
  "dg-flyDragIconDisableColor": "#CCC",
  "dg-estimateLengthColor": "#000",
  "dg-estimateLengthPeriodBg": "#DEDEDE",
  "dg-estimateLengthPeriodColor": "#333",
  "dg-estimateLengthPeriodCountBg": "#A4A4A4",
  "dg-estimateLengthPeriodEventBg": "#B8DAFF",
  "dg-estimateLengthPeriodEventBorder": "#FFF",
  "dg-appPerformanceBg": "#f6f6f6",
  "dg-appPerformanceHeadBgTop": "rgba(204,204,204,.7)",
  "dg-appPerformanceHeadBg": "rgba(204,204,204,.5)",
  "dg-appPerformanceData": "#333",
  "dg-appPerformanceBorder": "#BBB",
  "dg-toolTipBlackWhiteBg": "rgb(255,255,255)",
  "dg-toolTipBlackWhiteBorder": "rgb(0,0,0)",
  "dg-toolTipYellowBg": "rgb(255, 255, 179)",
  "dg-toolTipYellowBorder": "rgb(102, 102, 102)",
  "dg-toolTipYellowTexts": "#555",
  "dg-eventBadgeScrollBg": "rgba(255,255,255,.2)",
  "dg-eventBadgeScrollBorder": "rgba(255,255,255,.7)",
  "dg-toolTipLightYellowBg": "#FEFBCE",
  "dg-toolTipLightYellowTextColor": "#000",
  "dg-pointerChangesBg": "#FFF",
  "dg-pointerChangesColor": "#262626",
  "dg-pointerNoteBg": "#F1F1F1",
  "dg-pointerNoteBorder": "#CCC",
  "dg-pointerNoteBorderTop": "#666",
  "epsAnimationText": "#000000",
  "calInputDataValue1": "#838488",
  "calInputDataValue2": "#797a7e",
  "calInputDataValue3": "#707276",
  "calInputDataValue4": "#707276",
  "calInputDataValuehistoricalBg1": "#b6ad93",
  "calInputDataValuehistoricalBg2": "#9f9472",
  "calInputDataValuehistoricalBg3": "#82775d",
  "calInputDataValuehistoricalBg4": "#685f55",
  "shareMsgBg": "#afb3b6",
  "shareMsgBorder": "#767b81",
  "shareActionLink": "#000",
  "shareActionLinkHover": "#0066CC",
  "shareActionManageBtn": "#666",
  "priceLineFontColor": "#000",
  "priceLineHr": "#A0A0A0",
  "regionContryTop": "#FFF",
  "regionContryBtm": "#CFCFCF",
  "regionContryTopHover": "#E8F5FD",
  "regionContryBtmHover": "#BEE6FD",
  "regionContryTopSelected": "#C2E4F6",
  "regionContryBtmSelected": "#ABDAF3",
  "regionContryDisabled": "#F4F4F4",
  "regionContryDisabledBorder": "#ADB2B5",
  "regionContryBorder": "#707070",
  "regionContryBorderHover": "#3C7FB1",
  "regionContryBorderSelected": "#2C628B",
  "linePickerBgTop": "#F6F6F6",
  "linePickerBgBtm": "#B4B4B4",
  "linePickerBorder": "#000",
  "linePickerHr": "#000",
  "linePickerActive": "#B8DAFF",
  "autoFillInputBG": "#FFF",
  "autoFillInputBorder": "#AAA",
  "autoFillInputBGDisabled": "#F4F4F4",
  "autoFillInputBorderDisabled": "#ADB2B5",
  "supportText": "#777",
  "colorPickerBorder": "#666",
  "blackYellowRedBorder": "#000",
  "blackYellowRedBg": "#FEFBCE",
  "blackYellowRedColor": "#B00",
  "modalContentStroke": "#6B7076",
  "modalContentBackground": "#A6AAAD",
  "modalHeaderBackground": "#AFB3B6",
  "modalHeaderStrokeTop": "#DADCE0",
  "modalHeaderStrokeBtm": "#7F8790",
  "modalTitleColor": "#000",
  "modalCloseIcon": "-373px -398px",
  "modalBodyStrokeTop": "#DADCE0",
  "modalMaterialBackground": "#E8E8E8",
  "modalMaterialStroke": "#7F8790",
  "extSettingBorderColor": "#666",
  "extSettingIconColor": "#999",
  "extRiPanelParaColor": "#333",
  "extRiPanelBackground": "#F7F7F7",
  "extRiPanelGridLines": "#999",
  "extEditableText": "#018fee",
  "extEditableInputBG": "#FFF",
  "extEditableInputFont": "#333",
  "extUploadHeading": "#7abf8c",
  "extHelpTextColor": "#06C",
  "extHelpTextColorHover": "#000",
  "extHelpTextColorModalHover": "#000",
  "extInstructionSeprator": "#E2E5E6",
  "extenalDataBg": "#CACFD2",
  "extLoadSummaryTable": "#F1EFEF",
  "extLoadSummaryTableTitle": "#090",
  "extLoadSummaryTableBg": "#FFF",
  "extLoadSummaryTableBorder": "#CCC",
  "extLoadSummaryTableHeadColor": "#333",
  "extLoadSummaryTableHeadBg": "#E3FDA2",
  "extLoadSummaryTableBodyColor": "#333",
  "extLoadSummaryTableHeadBgChange": "#FFF",
  "extDragDropHolderBg": "rgba(255,255,255,.4)",
  "extDragDropHolderStrock": "#666",
  "extDragDropMsgStrock": "#777",
  "extDragDropMsgBg": "rgba(221,221,221,.5)",
  "extDragDropMsgText": "#666",
  "modalRegionBg": "#e8e8e8",
  "modalRegionBorder": "#7f8790",
  "extWizardBg": "rgba(202,207,210,.85)",
  "extWizardBackdrop": "rgba(255,255,255,.45)",
  "extWizardMsg": "#cacfd2",
  "extTipTopBorder": "#AAA",
  "extTipBtmBorder": "#666",
  "extTipBg": "#CCC",
  "extTipTxt": "#390",
  "update-setting-heading": "#000",
  "extUpdateHelpTextColor": "#039",
  "extUpdateHelpTextColorHover": "#000",
  "extUpdateSeprator": "#d5d4d4",
  "extAdditionalsBorder": "#CCC",
  "extAdditionalsBg": "#F5F5F5",
  "extAdditionalsHeading": "#000",
  "extAdditionalsLiHead": "#555",
  "extAdditionalsLiValue": "#111",
  "extAdditionalsCount": "#666",
  "extAdditionalsCountStroke": "#999",
  "extAdditionalsCountPara": "#777",
  "recurringFormBorder": "#CCC",
  "recurringFormBg": "#FFF",
  "recurringFormTitleColor": "#000",
  "recurringFormTitleBg": "#CCC",
  "recurringDropDownColor": "#3AA9FB",
  "popoverTimeSelectBorder": "#cccccc",
  "popoverTimeSelectBg": "#a6aaad",
  "eduWizardBg": "#FFF",
  "eduIdentifierBg": "#DDD",
  "eduGridListHolderBorder": "#CCC",
  "eduGridHeaderBorderColor": "#CCC",
  "eduGridHeaderTop": "#F1F1F1",
  "eduGridHeaderBtm": "#C6C6C6",
  "eduGridHeaderHoverTop": "#AAABB0",
  "eduGridHeaderHoverBtm": "#84858A",
  "eduGridHeaderSorterTop": "#CACACC",
  "eduGridHeaderSorterBtm": "#ABACAD",
  "eduGridHeaderCellWizardTop": "#aacd8d",
  "eduGridHeaderCellWizardBtm": "#8bb35a",
  "eduGridHeaderCellWizardHoverTop": "#c8e1b2",
  "eduGridHeaderCellWizardHoverBtm": "#9dc36e",
  "eduGridHeadCellFont": "#333",
  "eduGridHeadSortSelect": "#FFF",
  "eduGridBodyCellFont": "#555",
  "eduGridBodySortSelect": "#222",
  "eduGridHeadCellFontShadow": "#FFF",
  "extTiIndicatorSettingBg": "#fff",
  "extTiIndicatorSettingColor": "#000",
  "extTiIndicatorSettingStroke": "#cfcfcf",
  "extTiIndicatorSettingThBg": "#e1e1e1",
  "extTiIndicatorDefineSetBorder": "#999",
  "extTiIndiAssignNoteFont": "#acacac",
  "extTiIndiAssignAnchorLink": "#333",
  "extTiIndiAdvanceSettingHead": "#000",
  "extTiIndiAdvanceSettingPara": "#666",
  "extTiIndicatorEventBadegeColor": "#666",
  "extTiIndicatorEventBadegeBorder": "#ddd",
  "extTiIndicatorEventBadegeBg": "#fff",
  "extGridAnchoreLink": "#555",
  "extGridAnchoreLinkHover": "#039",
  "extGridAnchoreLinkRowHover": "#000",
  "extConfirmDateHeadColor": "#000",
  "extConfirmDateHeadShadow": "#FFF",
  "extConfirmDateIdentifireBg": "#FFF",
  "extConfirmDateIdentifireBorder": "#999",
  "extConfirmDateRecognizerBg": "#AFB3B6",
  "extConfirmDateRecognizerBorder": "#888",
  "extConfirmDateIdentifireLiBorder": "#DDD",
  "extConfirmDateIdentifireTxt": "#444",
  "extConfirmDateIdentifireLiHoverTop": "#0098cb",
  "extConfirmDateIdentifireLiHoverBtm": "#00739a",
  "extConfirmDateIdentifireHoverTxt": "#000",
  "extConfirmDateRecognizerColor": "#222",
  "modalMsgBg": "#cacfd2",
  "modalMsgFooterDivider": "#72787e",
  "linkOfModal": "#06C",
  "linkOfModalHover": "#000",
  "tabSwitchSelectedBorder": "#000",
  "tabSwitchSelectedBG": "#DDD",
  "outerDashLine": "#000",
  "targetDashLine": "#000000",
  "factorLinkColor": "#0083ff",
  "legendBackgroundColor": "#fff",
  "factorButtonHoverBackgroundColor": "#000",
  "finPanBg": "#ECECEC",
  "finBorder": "#8B929A",
  "finNavIndexBg": "#8B929A",
  "finNavIndexColor": "#FFF",
  "finNavIndexBgActive": "#000",
  "finTabContentBorder": "#8B929A",
  "finRestoreColor": "#000",
  "finRestoreColorDisable": "#555",
  "finCustomizeListStroke": "#666",
  "finCustomLargeListBg": "#BDDEBF",
  "finCustomLargeListBorder": "#666",
  "finCustomMediumListBg": "#CEF1CF",
  "finCustomSmallListBg": "#E9FEEA",
  "finListRotateColor": "#333",
  "finMetricCotentBorder": "#dbdbdb",
  "finMetricItemBgHover": "#89CFF0",
  "finTabListColor": "#666",
  "tabSameColorBorder": "#ECECEC",
  "finItemColor": "#000",
  "oneilBg": "#FFF",
  "oneilFocusBg": "#FFF",
  "oneilBtnBorderBottom": "#9B9B9B",
  "oneilContactPanelBg": "#FFF",
  "oneilContactBorderTop": "#444",
  "oneilContactHoverBg": "#EEE",
  "oneilConNameColor": "#000",
  "oneilConPhoneColor": "#000",
  "oneilConDesignationColor": "#666",
  "oneilConMailColor": "#04478A",
  "oneilConMailColorHover": "#006699",
  "oneilConMailColorFocus": "#023e5c",
  "oneilSwichHeadColor": "#666",
  "oneilHistoricBg": "#EEEEF0",
  "oneilHistoricHeadingBorder": "#CECED0",
  "oneilNoActivityColor": "#666",
  "oneilWellBordercolor": "#E3E3E3",
  "oneilWellBGHover": "#DDD",
  "oneilNameColor": "#04478A",
  "oneilNameColorHover": "#006699",
  "oneilNameColorFocus": "#023e5c",
  "oneilDateColor": "#666",
  "oneilAnalysisColor": "#04478A",
  "oneilAnalysisColorHover": "#006699",
  "oneilAnalysisColorFocus": "#023e5c",
  "oneilMoreBg": "#EEE",
  "oneilMoreBorderColor": "#DDD",
  "oneilMoreColor": "#04478A",
  "oneilMoreColorHover": "#006699",
  "oneilMoreColorFocus": "#023e5c",
  "oneilRIPanSwitchStrock": "#4a4a52",
  "miniListBoxBorderBtm": "#999",
  "miniListPlayerBorderTop": "#FFF",
  "bgMLButtonTop": "#393838",
  "bgMLButtonBtm": "#2D2D2D",
  "bgMLButtonTopHover": "#B3B3B3",
  "bgMLButtonBtmHover": "#777",
  "bgMLButtonBorder": "#E3E3E3",
  "datePickerCloseTop": "#CFCED3",
  "datePickerCloseBtm": "#919292",
  "datePickerCloseHover": "#B8DCFF",
  "datePickerDivider": "#484A52",
  "waiting": "-275px -500px",
  "addRows": "-175px -500px",
  "priceAlertDefault": "-150px -400px",
  "priceAlertHover": "-150px -425px",
  "priceAlertTrigger": "-175px -400px",
  "priceAlertTriggerHover": "-175px -425px",
  "listAlertDefault": "-200px -400px",
  "listAlertTrigger": "-200px -425px",
  "listAlertDefaultDisabled": "-225px -400px",
  "listAlertTriggerDisabled": "-225px -425px",
  "imageLightTheme": "-80px 0",
  "imageDarkTheme": "-230px 0",
  "imageNormalFilter": "0 -100px",
  "imageMetricFilter": "0 -30px",
  "imageAdditionalFilter": "0 -0",
  "imageAlertPanel": "-170px -100px",
  "imageAlertLinePen": "-170px -125px",
  "imageAlertTriggered": "-275px -125px",
  "radio": "/Asset/images/lightSprite.png",
  "radio2x": "/Asset/images/lightSprite2x.png",
  "annotationContentBg": "#EBEBEB",
  "annotationPaletteBg": "#5C5C5C",
  "annotateToolkitBg": "#EBEBEB",
  "annotateToolkitStroke": "#999",
  "annotateToolkitTitle": "#333",
  "paletteListBg": "#EBEBEB",
  "paletteBorderTop": "#D2D2D2",
  "paletteBorderInternal": "#999",
  "paletteLayerNameColor": "#333",
  "paletteLayerEditColor": "#002D58",
  "paletteLayerEditStroke": "#569DE5",
  "paletteLayerSelectColor": "#FFF",
  "paletteLayerSelectStroke": "#4D4F54",
  "paletteLayerSelectBgTop": "#818387",
  "paletteLayerSelectBgBtm": "#717377",
  "paletteLayerSelectiveBg": "#D0E4FD",
  "annotateToolBg": "#C9CCD1",
  "annotateToolLinkHover": "#FFF",
  "annotateToolLinkHoverBorder": "#000",
  "annotateToolLinkSelective": "#CFE4FC",
  "annotateToolLinkSelectiveBorder": "#999",
  "annotateToolSeprator": "#999",
  "annotateReportBg": "#C9CCD1",
  "annotateReportHead": "#333",
  "annotateReportData": "#000",
  "annotateReportLink": "#04478A",
  "annotateReportLinkHover": "#000",
  "footIconLiBorder": "#000",
  "annoFootIconBgTop": "#9F9F9F",
  "annoFootIconBgBtm": "#5E5F5F",
  "annotateSelectBorder": "#999",
  "paletteHeadingColor": "#333",
  "paletteTextColor": "#333",
  "paletteOpacitySliderBorder": "#808080",
  "paletteCheckboxTextColor": "#333",
  "AnntwoSiderBorder": "#000",
  "AnnColorPickerBg": "#D3D3D3",
  "AnnColorPickerBgInset": "#FFF",
  "AnnColorPickerStrokeInset": "#808080",
  "AnnWeightDropDownBg": "#ECEFF3",
  "AnnWeightStrokeBorder": "#000",
  "AnnWeightStrokeColor": "#000",
  "AnnWeightStrokeColorSelected": "#FFF",
  "annRangeSlidePointer": "-450px -375px",
  "annLayerSelectBgTop": "#818387",
  "annLayerSelectBgBtm": "#717377",
  "annLayerSelectListColor": "#FFF",
  "annLayerSelectListActive": "#B8DAFF",
  "annLayerSelectListInactive": "#AFAFAF",
  "annLayerSelectListColorActive": "#000",
  "annLayerInfoBg": "#D2D2D2",
  "annLayerInfoTextColor": "#333",
  "annFontSelectListActive": "#D0E5FF",
  "annFontSelectDropDownStroke": "#000",
  "transparent": "transparent",
  "btnSequenceSelectiveBg": "#CDE3FB",
  "btnSequenceSelectiveColor": "#000",
  "annDropDownOptionSelective": "-375px -425px",
  "annMeasureTitleAndBorder": "#656565",
  "annMeasureTitleFontColor": "#FFF",
  "annMeasureStuffBg": "#ECECEC",
  "annMeasureStuffHeadFontColor": "#444",
  "annMeasureCover": "rgba(0, 0, 0, 0.15)",
  "annoOverlape": "rgba(0, 0, 0, 0.3)",
  "qqGridSeparatorBg": "#EEE",
  "qqGridSeparator": "#EEE",
  "qqGridBg": "#FFF",
  "qqGridBorder": "#CCC",
  "qqGridStroke": "#CCC",
  "qqGridFont": "#333",
  "qqInternalBg": "#FFF",
  "qqInternalBorder": "#999",
  "quiQuoH5": "#333",
  "qqHeads": "#666",
  "qqValue": "#000",
  "qqStatisticsFirstChild": "#000",
  "qqStatisticsFont": "#333",
  "qqInformationBg": "#F3F3F3",
  "qqInformationStroke": "#BBB",
  "qqParaFontColor": "#333",
  "qqLink": "#04478A",
  "nupSvBorder": "#2E2E2E",
  "nupSvMenuBorder": "#777B81",
  "nupSvMenuBG": "#EFEFF8",
  "nupSvMenuLiFontColor": "#333",
  "nupSvMenuLiBorder": "#CCC",
  "nupSvMenuLiHoverBg": "#B8DAFF",
  "nupSvMenuLiHoverBorder": "#FFF",
  "nupSvMenuPeriodcityShadow": "#CCC",
  "nupFlagTop": "#999",
  "nupFlagTopHover": "#3AB9CE",
  "nupFlagBtmActive": "#008FAE",
  "nupFlagBtmActiveHover": "#3AB9CE",
  "bgNupViewGrid": "#FFF",
  "bgNupViewGridBoxBg": "#D3D3D3",
  "colHeadPeriodicity": "#6C6E74",
  "dropdownTextColor": "#444",
  "dropdownTextColorEvent": "#000",
  "dropdownBorderColor": "#FFF",
  "nupBoxFontColor": "#333",
  "nupBoxFontColorHover": "#04478A",
  "nupBoxParaColor": "#333",
  "nupBoxStrokeColor": "#CDCDCD",
  "nupVolTitleColor": "#444",
  "nupBoxSelective": "#000",
  "nupBoxClicked": "#B8DAFF",
  "nupInfotableOddColor": "#666",
  "nupInfotableOddborder": "#CCC",
  "nupInfotableEvenColor": "#000",
  "icnListStandard": "-450px -250px",
  "icnListMultiChart": "-450px -275px",
  "icnListMultiChartDD": "-450px -352px",
  "icnListAnalytics": "-450px -300px",
  "icnListQuoteBoard": "-450px -325px",
  "icnListHoverStandard": "-500px -250px",
  "icnListHoverMultiChart": "-500px -275px",
  "icnListHoverMultiChartDD": "-475px -352px",
  "icnListHoverAnalytics": "-500px -300px",
  "icnListHoverQuoteBoard": "-500px -325px",
  "icnListSelectiveStandard": "-550px -250px",
  "icnListSelectiveMultiChart": "-550px -275px",
  "icnListSelectiveMultiChartDD": "-475px -377px",
  "icnListSelectiveAnalytics": "-550px -300px",
  "icnListSelectiveQuoteBoard": "-550px -325px",
  "messageText": "#666",
  "switchBorderLightTheme": "transparent"
};
